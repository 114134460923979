<template>
  <div class="do-page-personalcenter">
    <base-nav-bar
      @click-left="toPath('/my')"
      left-arrow
      title="个人信息"
    ></base-nav-bar>
    <van-popup v-model:show="sexPicker" round position="bottom">
      <van-picker
        class="do-com-picker"
        title="选择性别"
        :default-index="computedSexText === '男' ? 0 : 1"
        :columns="sexColumns"
        @cancel="sexPicker = false"
        @confirm="onSexPickerConfirm"
      />
    </van-popup>
    <van-popup v-model:show="industryPicker" round position="bottom">
      <van-picker
        class="do-com-picker"
        title="所属行业"
        :columns="computedIndustryColumns"
        :columns-field-names="{
          text: 'name',
          values: 'id',
          children: 'children',
        }"
        @cancel="industryPicker = false"
        @confirm="onIndustryPickerConfirm"
      />
    </van-popup>
    <van-popup v-model:show="cityPicker" round position="bottom">
      <van-picker
        class="do-com-picker"
        title="选择地区"
        :columns="computedCityColumns"
        :columns-field-names="{
          text: 'name',
          values: 'code',
          children: 'child',
        }"
        @cancel="cityPicker = false"
        @confirm="onCityPickerConfirm"
      />
    </van-popup>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-personalcenter-content">
        <div class="user-icon">
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :preview-image="false"
            :before-read="beforeRead"
            :after-read="afterRead"
            :deletable="false"
            image-fit="cover"
          >
            <img
              :src="
                $store.state.user.info.avatar ||
                require('@/assets/images/my/userlogo.png')
              "
            />
            <div class="edit-wrap">
              <img :src="require('@/assets/images/my/edit.png')" />
            </div>
          </van-uploader>
        </div>

        <div class="do-com-cellitem-group">
          <div
            class="do-com-cellitem"
            @click="
              router.replace({
                path: '/my/personal-center/set-base-info/nickname',
              })
            "
          >
            昵称
            <div class="right">
              <span class="text">{{
                $store.state.user.info.nickname || ""
              }}</span>
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <div
            class="do-com-cellitem"
            @click="
              router.replace({
                path: '/my/personal-center/set-base-info/name',
              })
            "
          >
            姓名
            <div class="right">
              <span class="text">{{ $store.state.user.info.name || "" }}</span>
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <div class="do-com-cellitem" @click="sexPicker = true">
            性别
            <div class="right">
              <span class="text">{{ computedSexText }}</span>
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <div class="do-com-cellitem" @click="cityPicker = true">
            地区
            <div class="right">
              <span class="text"> {{ computedCityInfoText }}</span>
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <!-- <div class="do-com-cellitem" @click="industryPicker = true">
            所属行业
            <div class="right">
              <span class="text"> {{ computedIndustryInfoText }}</span>
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div> -->
          <div class="do-com-cellitem" @click="() => {}">
            手机号
            <div class="right" :style="{ marginRight: '0.1rem' }">
              {{ $ComJs.geTel($store.state.user.phone) || "-" }}
            </div>
          </div>
          <div
            class="do-com-cellitem"
            @click="
              router.replace({
                path: '/my/personal-center/set-base-info/mail',
              })
            "
          >
            邮箱
            <div class="right">
              <span class="text">{{ $store.state.user.info.mail || "" }}</span>
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useState } from "@/hook/useState";
import { sendAccountInfo } from "@/axios/user";
import { fileUplload } from "@/axios/common";
import { Toast } from "vant";
const store = useStore();
const router = useRouter();

// 性别 / 地区 / 行业  Picker 组件显示
const sexPicker = ref(false);
const industryPicker = ref(false);
const cityPicker = ref(false);
const loading = ref(false);
// 头像上传ref
const fileList = ref([]);
// 获取 store 的 common/user 模块
const storeStateFns = useState(["common", "user"]);
// sex: value === "男" ? 1 : 2,
const sexColumns = ["男", "女"];
const sexType = {
  0: "未知",
  1: "男",
  2: "女",
  男: 1,
  女: 2,
};

// vuex 没有城市和行业列表数据，进行请求
onMounted(() => {
  const citys = storeStateFns.common.value.citys;
  const industry = storeStateFns.common.value.industry;
  if (citys.length === 0) {
    store.dispatch("getCity");
  }
  if (industry.length === 0) {
    store.dispatch("getIndustry");
  }
});

function beforeRead(file) {
  if (file.size > 3096000) {
    Toast("图片大小不能超过3M");
    return false;
  }
  return true;
}
async function afterRead(file) {
  const toast = Toast.loading("上传头像中");
  try {
    const res = await fileUplload(file);
    if (res.code === 0) {
      await sendUpdata({
        avatar: res.data.download_url,
      });
    } else {
      Toast("上传头像失败～");
    }
  } catch (error) {
    Toast("上传头像失败～");
  }
  toast.clear();
  fileList.value = [];
}

// 格式化 picker 城市列表数据
const computedCityColumns = computed(() => {
  try {
    // store 城市数据
    const citys = storeStateFns.common.value.citys;
    const pickerCitys = JSON.parse(JSON.stringify(citys));
    // 保证三级兼容 vant-picker 组件
    pickerCitys.forEach((item) => {
      if (!item.child || item.child.length === 0) {
        const nullData = { code: undefined, name: "" };
        item.child = [
          {
            ...nullData,
            child: [nullData],
          },
        ];
      }
    });
    return pickerCitys;
  } catch (error) {
    return [];
  }
});

// picker 行业列表数据
const computedIndustryColumns = computed(() => {
  try {
    const industry = storeStateFns.common.value.industry;
    return industry;
  } catch (error) {
    return [];
  }
});

// 用户当前选择城市数据格式化 xx省/xx市/xx区
const computedCityInfoText = computed(() => {
  try {
    const citys = storeStateFns.common.value.citys;
    // 序列化 citys 所有数据到info 对象
    const info = {};
    const dsp = (data) => {
      data.map((item) => {
        info[item.code] = item.name;
        if (item.child && item.child.length) {
          dsp(item.child);
        }
      });
    };
    dsp(citys);
    let text = "";
    const region = storeStateFns.user.value.info.region;
    region.map((item, index) => {
      if (info[item]) {
        text += region[index + 1] ? info[item] + "/" : info[item];
      }
    });
    return text || "未知";
  } catch (error) {
    return "未知";
  }
});

// 用户当前选择行业数据格式化 xx行业/xx子级
const computedIndustryInfoText = computed(() => {
  try {
    const info = {};
    const industry = storeStateFns.common.value.industry;
    const dsp = (data) => {
      data.map((item) => {
        info[item.id] = item.name;
        if (item.children && item.children.length) {
          dsp(item.children);
        }
      });
    };
    dsp(industry);
    let text = "";
    const userIndustry = storeStateFns.user.value.info.industry;
    userIndustry.map((item, index) => {
      if (info[item]) {
        text += userIndustry[index + 1] ? info[item] + "/" : info[item];
      }
    });

    return text || "未知";
  } catch (error) {
    return "未知";
  }
});

// 性别
const computedSexText = computed(() => {
  try {
    const sexValue = storeStateFns.user.value.info.sex;
    return sexType[sexValue] || "未知";
  } catch (error) {
    return "未知";
  }
});

// 修改用户个人信息
async function sendUpdata(data) {
  const toast = Toast.loading();
  if (loading.value) return;
  try {
    loading.value = true;
    await sendAccountInfo(data);
    await store.dispatch("getAccountInfo");
    loading.value = false;
  } catch (error) {
    if (error) {
      Toast({
        message: error.msg || "更改失败，未知错误",
      });
    }
  }
  toast.clear();
  setTimeout(() => {
    loading.value = false;
  }, 200);
}

// 城市picker
async function onCityPickerConfirm(value) {
  const region = [];
  // 过滤
  for (let index = 0; index < value.length; index++) {
    const element = value[index];
    if (element.code === undefined) break;
    region.push(Number(element.code));
  }
  await sendUpdata({
    region: region,
  });
  cityPicker.value = false;
}

// 行业picker
async function onIndustryPickerConfirm(value) {
  await sendUpdata({
    industry: value.map((item) => Number(item.id)),
  });
  industryPicker.value = false;
}

// 性别picker
async function onSexPickerConfirm(value) {
  await sendUpdata({
    sex: sexType[value],
  });
  sexPicker.value = false;
}

function toPath(path) {
  router.replace(path);
}
</script>

<style lang="scss">
.do-page-personalcenter {
  .do-com-scroll-wrapper {
    background: $col_bg;
  }
  .van-uploader {
    height: 1.42rem;
  }
  &-content {
    padding: 0.5rem 0.24rem 0 0.24rem;
    .edit-wrap {
      position: absolute;
      margin-left: 0.9rem;
      bottom: 0;
      background: #0057ff;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.25rem !important;
        height: 0.25rem !important;
      }
    }
    .do-com-cellitem-group {
      position: relative;
      top: -0.42rem;
      z-index: 10;
      // transform: translateY(-0.38rem);
      .do-com-cellitem {
        &:first-child {
          padding-top: 0.38rem;
        }
      }
    }
    .user-icon {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 100;
      img {
        width: 1.36rem;
        height: 1.36rem;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0.12rem 0.24rem rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
